<template>
  <div class="container">
    <Header />
    <div class="main">
      <el-row>
        <el-col :span="16">
          <h2 style="color: #2766ba;">{{ $t(`reservationStatus.${getStatus(reservation)}`) }}</h2>
          <div style="color: #2766ba;" v-if="reservation.payment_status === 'Unpaid' && reservation.status === 'Reserved'">
            {{ $t('Please complete the payment in {day} day(s) {hour} hour(s) otherwise your booking will be cancel', { day, hour }) }}
          </div>

          <el-row class="cancellation" style="margin-top: 50px;">
            <ul>
              <li v-for="(item, index) of cancellationPolicies" :key="index" style="color: #515a6e;">
                {{ item.description }}
              </li>
            </ul>
          </el-row>

          <div class="reservation">
            <div class="block">
              <h2>{{ $t('yourInformation') }}</h2>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('mobileNumber') }}</el-col>
                <el-col :span="19">{{ reservation.contact_number }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('email') }}</el-col>
                <el-col :span="19">{{ reservation.email }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('name') }}</el-col>
                <el-col :span="19">{{ reservation.last }} {{ reservation.first }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ reservation.idType && reservation.idType.name || '' }}</el-col>
                <el-col :span="19">{{ reservation.id_number }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('accompany') }}</el-col>
                <el-col :span="19">{{ reservation.accompany }}</el-col>
              </el-row>

              <h2>{{ $t('bookingInformation') }}</h2>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('bookingNumber') }}</el-col>
                <el-col :span="19">{{ reservation.booking_number }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('hotelConfirmNumber') }}</el-col>
                <el-col :span="19">{{ reservation.confirm_number }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('roomType') }}</el-col>
                <el-col :span="19">{{ reservation.roomType && reservation.roomType.name || '' }} ({{ reservation.roomType && reservation.roomType.breakfast || '' }})</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('invitationCode1') }}</el-col>
                <el-col :span="19">{{ reservation.invitation_code }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('checkin') }}</el-col>
                <el-col :span="5">{{ reservation.arrival | formatDate }}</el-col>
                <el-col :span="3">
                  <el-tag
                    type="primary"
                    effect="dark">
                    {{ reservation.nights }} {{ $t('nights') }}
                  </el-tag>
                </el-col>
                <el-col :span="5">{{ $t('checkout') }}</el-col>
                <el-col :span="5">{{ reservation.departure | formatDate }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('arrivalTime') }}</el-col>
                <el-col :span="5">{{ arrivalTime }}</el-col>
                <el-col :span="5" :offset="3">{{ $t('departureTime') }}</el-col>
                <el-col :span="5">{{ departureTime }}</el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="5">{{ $t('bookingDate') }}</el-col>
                <el-col :span="19">{{ reservation.created_at | formatDate('YYYY-MM-DD HH:mm:ss') }}</el-col>
              </el-row>
            </div>
          </div>

        </el-col>

        <el-col :span="8" style="padding: 20px;">
          <el-row>
            <el-image style="width: 100%; height: 360px;" lazy fit="cover" :src="hotel.images && hotel.images[0] || '#'" />
          </el-row>

          <el-row>
            <div class="hotelname">{{ hotel.name }}</div>
          </el-row>

          <el-row>
            <div class="hoteladdress"><span class="iconfont icon-iconlocation" /> {{ hotel.address }}</div>
          </el-row>

          <el-row class="rate">
            <el-col :span="12">{{ $t('finalPrice') }}</el-col>
            <el-col :span="12" style="text-align: right;">{{ hotel.currency_code }}{{ reservation.total_amount | formatNumber}}</el-col>
          </el-row>

          <el-divider />
          <div>
            <div>{{ $t('detail') }}</div>
            <el-row
              v-for="(item, index) of details"
              :key="index"
              style="color: #5c5c5c; padding: 6px 0;"
            >
              <el-col :span="12">{{ item.reservation_date }}</el-col>
              <el-col :span="12" style="text-align: right;">{{ hotel.currency_code }} {{ item.rate_amount | formatNumber }}</el-col>
            </el-row>
          </div>
          <el-divider />

          <div v-if="reservation.status !== 'Cancelled'" style="display: flex;  flex-direction: row; justify-content: space-around; padding: 10px 0;">
            <van-button
              v-if="reservation.payment_status === 'Unpaid' && reservation.status !== 'No Show'"
              style="width: 30%;"
              size="large"
              type="warning"
              @click="goPayment(reservation)"
            >
              {{ $t('payNow') }}
            </van-button>
            <van-button
              style="width: 30%;"
              size="large"
              plain
              type="primary"
              @click="goInvoice(reservation)"
            >
              {{ $t('invoice') }}
            </van-button>
            <van-button
              v-if="reservation.payment_status === 'Unpaid' && reservation.status === 'Reserved'"
              style="width: 30%;"
              size="large"
              plain
              type="danger"
              @click="cancelReservation(reservation)"
            >
              {{ $t('cancel') }}
            </van-button>
          </div>
        </el-col>
      </el-row>

      <div style="flex: 1;"></div>
      <Footer/>

    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import _ from 'lodash'
import Header from '@/components/Header'
import Hotel from '@/models/hotel'
import Reservation from '@/models/reservation'
import RoomType from '@/models/roomType'
import { getDates, reservationTimes, countDown } from '@/utils'
import { getLabel } from '@/filters'
import { Notify } from 'vant'
import CountDown from '@/mixins/count-down'

export default {
  name: 'ReservationDetail',
  components: {
    Header
  },
  mixins: [CountDown],
  data () {
    return {
      showPolicy: false,
      accepted: true,
      loading: false,
      hotel: new Hotel(),
      roomType: new RoomType(),
      cancellationPolicies: [],
      idTypes: [],
      reservation: new Reservation()
    }
  },
  computed: {
    nights () {
      return ((this.reservation.departure - this.reservation.arrival) / 86400000).toFixed(0)
    },
    details () {
      const dates = getDates(this.reservation.arrival, this.reservation.departure)
      if (dates.length > 1) {
        dates.pop()
      }

      return dates.map(e => ({
        reservation_date: dayjs(e).format('YYYY-MM-DD'),
        rate_amount: this.roomType.rate_amount
      }))
    },
    arrivalTime () {
      if (this.reservation.arrival_time) {
        return getLabel(_.find(reservationTimes, { code: this.reservation.arrival_time }))
      }

      return '-'
    },
    departureTime () {
      if (this.reservation.departure_time) {
        return getLabel(_.find(reservationTimes, { code: this.reservation.departure_time }))
      }

      return '-'
    }
  },
  async mounted () {
    try {
      try {
        await this.loadReservation()
        const res = await this.$axios.get(`/invitation/hotel-detail/${this.reservation.hotel_id}/room-type-detail/${this.reservation.room_type_id}`)
        Object.assign(this.hotel, res.data.hotel)
        Object.assign(this.roomType, res.data.roomType)
        this.cancellationPolicies = res.data.cancellationPolicies
      } catch (e) {
        Notify(e.message)
      }
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    getStatus (reservation) {
      if (reservation.payment_status === 'Unpaid') {
        if (reservation.status === 'Cancelled' || reservation.status === 'No Show') {
          return reservation.status
        }

        return reservation.payment_status
      }

      return reservation.status
    },
    goInvoice (reservation) {
      this.$router.push({
        name: 'Invoice',
        params: { reservationId: reservation.id }
      })
    },
    goPayment (reservation) {
      this.$router.push({
        name: 'Payment',
        params: { reservationId: reservation.id }
      })
    },
    async cancelReservation (reservation) {
      await this.$confirm(this.$t('confirm to cancel this booking') + '?')
      const _this = this
      try {
        _this.loading = true
        await _this.$axios.put(`/reservation/${reservation.id}/cancel`)
      } catch (e) {
        Notify(e.message)
      } finally {
        _this.loading = false
        await _this.loadReservation()
      }
    },
    async loadReservation () {
      try {
        const response = await this.$axios.get(`/reservation/${this.$route.params.reservationId}`)
        this.reservation = response.data
        countDown.bind(this)(this.reservation.last_pay_time)
      } catch (e) {
        Notify(e.message)
      }
    },
    async save () {
      await this.$refs.form.validate()
      if (!this.accepted) {
        Notify(this.$t('please read the cancellation policy carefully and check accepted'))
        return
      }
      // 提交预订
      try {
        this.loading = true
        const res = await this.$axios.post('/reservation', this.reservation)

        this.$router.push({
          name: 'Payment',
          params: { reservationId: res.data.id }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.reservation {
  padding: 1rem;

  .el-row {
    padding: 10px 0;
    color: #515a6e;
  }
}

.hotelname {
  padding-top: 20px;
  font-size: 1.5rem;
  color: #333333;
}

.hoteladdress {
  padding: 20px 0;
  color: #5c5c5c;
}
</style>
